<script>
import axios from "axios";
export default {
  props: ["id"],
  data() {
    return {
      dialog: false,
      search: "",
      dadosRecuperados: [],
      headers: [
        { text: "Razão Social", value: "razao_social_nome", sortable: false },
        {
          text: "Nome Fantasia",
          value: "nome_fantasia_sobrenome",
          sortable: false,
        },
        { text: "CNPJ_CPF", value: "cnpj_cpf", sortable: false },
        { text: "IE_RG", value: "ie_rg", sortable: false },
        { text: "Acões", value: "action", sortable: false },
      ],
      items: [],
      loading: false,
      message: {
        snacshow: false,
        text: "",
        type: "",
        mode: "",
        timeout: 2000,
      },
      dados: {
        razao_social_nome: "",
        nome_fantasia_sobrenome: "",
        cnpj_cpf: "",
        ie_rg: "",
        logradouro: "",
        bairro: "",
        cep: "",
        telefone: "",
        cidade: "",
        uf: "",
        email: "",
      },
      valid: true,
      fieldRules: [(v) => !!v || "Este campo é requerido"],
      emailRules: [
        (v) => !!v || "E-mail é requerido",
        (v) => /.+@.+\..+/.test(v) || "Digite um e-mail válido!",
      ],
    };
  },
  mounted() {
    this.initialize();
  },
  methods: {
    async initialize() {
      this.loading = true;
      const getClients = await this.$http.get("/encomendas/clientes");
      this.items = getClients.data;
      this.loading = false;
    },
    async editarItem(id) {
      this.$router.push(`/encomendas/clientes/editar/${id}`);
    },
    async deleteItem(id) {
      let c = confirm("Deseja excluir esse cliente?");
      if (c) {
        this.loading = true;
        this.$http
          .post(`/encomendas/clientes/delete/${id}`)
          .then((response) => {
            this.message.snacshow = true;
            this.message.type = "success";
            this.message.text = response.success;
            this.loading = false;
            this.initialize();
          })
          .catch((e) => {
            this.message.snacshow = true;
            this.message.type = "warning";
            this.message.text = "Atencão cep não encontrado!" + e;
            this.loading = false;
          });
      }
    },
    getCep(cep) {
      if (cep.length == 10) {
        this.loading = true;
        cep = cep.replace("-", "");
        cep = cep.replace(".", "");
        axios
          .get("https://viacep.com.br/ws/" + cep + "/json/")
          .then((response) => {
            this.dados.logradouro = response.data.logradouro;
            this.dados.bairro = response.data.bairro;
            this.dados.cidade = response.data.localidade;
            this.dados.uf = response.data.uf;
            this.dados.cibge = response.data.ibge;
            this.loading = false;
          })
          .catch((e) => {
            this.message.snacshow = true;
            this.message.type = "warning";
            this.message.text = "Atencão cep não encontrado!" + e;
            this.loading = false;
          });
      }
    },
  },
};
</script>
